import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">Wie geht's weiter?</h2>

      <h2 className="title">Projekt anfragen</h2>

      <p>
        Obwohl ich derzeit nicht aktiv auf der Suche nach neuen Projekten bin,
        sehe ich mich sets nach neuen Gelegenheiten um.
        Ich freue mich daher jederzeit über neue Kontaktanfragen in meinem Posteingang.
        Ob Anfragen zu Projekten oder zur Unterstützung sowie allerlei Fragen:
        Ich werde mein Bestes geben um möglichst bald auf Ihre Nachricht zu antworten.
      </p>

      <a className="email-link" href={`mailto:${email}`}>
        E-Mail schreiben
      </a>
    </StyledContactSection>
  );
};

export default Contact;
